<!-- 项目配置弹窗 -->
<template>
  <div>
    <el-dialog
        width="40%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'项目配置':'项目配置'"
        @update:visible="updateVisible">
      <el-form
          ref="form"
          :model="form"
          label-width="140px">

        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="服务项目" label-width="100px">
              <el-select
                  clearable
                  v-model="form.status"
                  placeholder="请选择"
                  class="ele-fluid">
                <el-option label="拖车" value="1"/>
                <el-option label="搭电" value="2"/>
                <el-option label="换电" value="3"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目属性" prop="">
              <el-select
                  clearable
                  v-model="form.status"
                  placeholder="请选择"
                  class="ele-fluid">
                <el-option label="拖车" value="1"/>
                <el-option label="搭电" value="2"/>
                <el-option label="换电" value="3"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <div style="display: flex;justify-content: space-between;">
            <div style="display: flex;align-items: center;">
              <span style="width: 5px;height: 16px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 700;color: #3f4155;">默认拍照模板</span>
            </div>
            <div>
              <el-button style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;">
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle" @click="add()">添加拍照模板</span>
              </el-button>
            </div>
          </div>
        </el-row>

        <div style="margin-top: 20px;background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 6px;padding: 20px 20px 0 20px;">
          <el-form-item label="救援地拍照模板：" prop="">
            <el-select
                clearable
                v-model="form.status"
                placeholder="请选择"
                class="ele-fluid">
              <el-option label="拖车" value="1"/>
              <el-option label="搭电" value="2"/>
              <el-option label="换电" value="3"/>
            </el-select>
          </el-form-item>
          <el-form-item label="目的地拍照模板：" prop="">
            <el-select
                clearable
                v-model="form.status"
                placeholder="请选择"
                class="ele-fluid">
              <el-option label="拖车" value="1"/>
              <el-option label="搭电" value="2"/>
              <el-option label="换电" value="3"/>
            </el-select>
          </el-form-item>
        </div>



      </el-form>
      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="save">确定
        </el-button>
      </div>
    </el-dialog>


    <el-dialog
        width="80%"
        center
        :visible="photoTemplate"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'拍照模板':'拍照模板'"
        @update:visible="photoTemplateVisible">

      <el-form
          ref="form"
          :model="form"
          label-width="100px">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="模板名称：">
              <el-input
                  placeholder="请输入内容"
                  v-model="form.name"
                  clearable>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <el-col :span="12">
            <div style="display: flex;align-items: center;margin-top: 11px;">
              <span style="width: 5px;height: 16px;background: #ff9b05;display:inline-block; margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 700;color: #3f4155;">已添加的示例图</span>
            </div>
            <div style="margin-top: 20px;padding: 20px;background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 6px;">
              <el-row style="background: #E9ECF1;text-align: center;">
                <el-col :span="4" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  编号
                </el-col>
                <el-col :span="8" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  名称
                </el-col>
                <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  示例图
                </el-col>
                <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  操作
                </el-col>
              </el-row>

              <draggable v-model="list" group="people" @change="log">
                <template>
                  <el-row style="background: #FFFFFF;text-align: center; cursor: pointer;" v-for="(item,index) in list" :key="index">
                    <el-col :span="4" style=" border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                      <span>{{item.id}}</span>
                    </el-col>
                    <el-col :span="8" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;">
                      <span style="margin-left: 8px;margin-right: 8px;">{{item.name}}</span>
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                      <img :src="item.img" alt="" style="width: 50px;height: 45px;">
                    </el-col>
                    <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                      <span style="margin-right: 15px;">拖动排序</span>
                      <el-popconfirm title="确定删除吗？">
                        <span slot="reference" @click="del(item)">删除</span>
                      </el-popconfirm>
                    </el-col>
                  </el-row>
                </template>
              </draggable>
            </div>
          </el-col>

          <el-col :span="12">
            <el-row :gutter="15" style="display: flex;align-items: center;">
              <el-col :span="6">
                <div style="display: flex;align-items: center;">
                  <span style="width: 5px;height: 16px;background: #ff9b05;display:inline-block; margin-right: 10px;"></span>
                  <span style="font-size: 18px;font-weight: 700;color: #3f4155;">选择示例图</span>
                </div>
              </el-col>
              <el-col :span="10" style="display: flex;">
                <el-input
                    placeholder="请输入内容"
                    v-model="form.input"
                    clearable>
                </el-input>
                <el-button type="primary" icon="el-icon-search">搜索</el-button>
              </el-col>
              <el-col :span="8">
                <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;">
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle" @click="addimg()">添加示例图</span>
                </el-button>
              </el-col>
            </el-row>

            <div style="margin-top: 20px;padding: 20px;background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 6px;">
              <el-row style="background: #E9ECF1;text-align: center;">
                <el-col :span="4" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  编号
                </el-col>
                <el-col :span="8" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  名称
                </el-col>
                <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  示例图
                </el-col>
                <el-col :span="6" style="border: 1px solid #ebeaea;padding-top: 15px;padding-bottom: 15px;">
                  操作
                </el-col>
              </el-row>

              <el-row style="background: #FFFFFF;text-align: center; cursor: pointer;" v-for="(item,index) in list" :key="index">
                <el-col :span="4" style=" border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                  <span>{{item.id}}</span>
                </el-col>
                <el-col :span="8" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;">
                  <span style="margin-left: 8px;margin-right: 8px;">{{item.name}}</span>
                </el-col>
                <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                  <img :src="item.img" alt="" style="width: 50px;height: 45px;">
                </el-col>
                <el-col :span="6" style="border: 1px solid #ebeaea;height: 60px;display: flex;align-items: center;justify-content: center;">
                  <el-popconfirm title="确定添加吗？">
                    <span slot="reference" style="color: #FF9B05;">添加到模板</span>
                  </el-popconfirm>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button
            @click="photoTemplateVisible()">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="photoTemplateVisible()">确定
        </el-button>
      </div>
    </el-dialog>

    <!--添加示例图-->
    <el-dialog
        width="30%"
        center
        :visible="addSampleImage"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'添加示例图':'添加示例图'"
        @update:visible="addSampleImageVisible">

      <el-form
          ref="form"
          :model="form"
          label-width="100px">
        <el-row :gutter="15">
          <el-col :span="24">
            <el-form-item label="示例图名称">
              <el-input
                  placeholder="请输入示例图名称"
                  v-model="form.name"
                  clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="拍照要求:">
              <el-input
                  placeholder="请输入拍照要求"
                  v-model="form.name"
                  clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="拍照图例">
              <div class="upload_bg_shouchi">
                <el-upload
                    :limit="1"
                    :class="{hide_box: handupload_btn}"
                    :on-change="handchange"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    list-type="picture-card"
                    :on-preview="handPreview"
                    :on-remove="handhandleRemove">
                  <!--<i class="el-icon-plus"></i>-->
                  <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                  <div style="font-size: 12px;font-weight: 500;color: #ff9b05;margin-top: 10px;">上传营业执照</div>
                  <div style="font-size: 12px;font-weight: 400;color: #c1c2ce;margin-top: 20px;">支持jpg，jpeg，png格式，最大10M</div>
                </el-upload>
                <el-dialog :visible.sync="handdialogVisible">
                  <img width="100%" :src="handdialogImageUrl" alt="">
                </el-dialog>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button
            @click="addSampleImageVisible()">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="addSampleImageVisible()">确定
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
//拖动排序
import draggable from 'vuedraggable'
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    draggable
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //拍照模板弹窗
      photoTemplate:false,
      //已添加的示例图数组
      list:[
        {
          id:'00021',
          name:'现场照片',
          img:require('../../../../assets/images/home/yunli/shilitu.png')
        },
        {
          id:'00023',
          name:'04.23道路救援现场照片23道路救援现场照片',
          img:require('../../../../assets/images/home/yunli/shilitu.png')
        },
      ],

      // 添加示例图弹窗
      addSampleImage:false,
      //营业执照照片
      handdialogImageUrl:'',
      handdialogVisible:false,
      handupload_btn:false,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //点击添加照片模板
    add(){
      //关闭项目配置弹窗
      this.updateVisible(false);
      //显示添加照片模板弹窗
      this.photoTemplate = true;
    },
    //关闭添加照片模板弹窗
    photoTemplateVisible(){
      this.photoTemplate = false;
      this.updateVisible(true);
    },

    // 拖动排序事件
    log: function(evt) {
      window.console.log(evt);
    },
    // 删除
    del(item){
      console.log(item);
      // this.$utils.confirm("确定要删除吗？", function () {
      //   // 执行一些需要的逻辑
      //   console.log('点击确定')
      // }, "确定", 'warning');
      console.log('点击删除')
    },

    // 点击添加示例图
    addimg(){
      //显示添加示例图
      this.addSampleImage = true;
      //隐藏添加照片模板
      this.photoTemplate = false;
      //隐藏项目配置弹窗
      this.updateVisible(false);
    },
    // 关闭添加示例图弹窗
    addSampleImageVisible(){
      this.addSampleImage = false;
      this.photoTemplate = true;
      this.updateVisible(false);
    },

    //营业执照照片事件
    handchange(){
      this.handupload_btn = true;
    },
    handhandleRemove(){
      this.handupload_btn = false;
    },
    handPreview(file){
      this.handdialogImageUrl = file.url;
      this.handdialogVisible = true;
    },

  }
}
</script>

<style lang="scss" scoped>
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
