<template>
  <!--运力 服务商列表 服务商详情 系统设置 项目配置-->
  <div style="margin-top: 20px;padding: 20px;">
    <div style="display: flex;justify-content: space-between;">
      <el-button style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;">
        <i class="el-icon-tianjia" />
        <span style="vertical-align: middle" @click="openEdit(null)">添加项目配置</span>
      </el-button>
      <el-button @click="returnPage">返回</el-button>
    </div>

    <!--数据表格-->
    <div style="margin-top: 20px;">
      <my-table
          ref="myTable"
          :columns="columns"
          :tableData="List"
          :cellStyle="cellStyle"
          :headerCellStyle="headerCellStyle"
          :rowClassName="rowClassName"
          :total="total"
          :customsFromWhere="where"
          :loading="loading"
          @select="select"
          @ChangeSize="ChangeSize"
          @currentChange="currentChange"
      >

        <!--自定义列显示 示例-->
        <template v-slot:status= "{ scope }">
          <div >
            <img style="cursor: pointer" src="@/assets/images/home/yiqiyong.png" alt="" v-if="scope.row.status == 0" @click="Enable(scope.row)">
            <img style="cursor: pointer" src="@/assets/images/home/weiqiyong.png" alt="" v-else @click="Disable(scope.row)">
          </div>
        </template>

        <!--自定义列显示 示例-->
        <template v-slot:authentication_status= "{ scope }">
          <div v-if="scope.row.authentication_status == 0">
            <img src="@/assets/images/home/yunli/duihao.png" alt="">
          </div>
          <div v-else>
            <img src="@/assets/images/home/yunli/cuohao.png" alt="">
          </div>
        </template>


        <!-- 不使用数据可不接收scope值 -->
        <template v-slot:operationSlot= "{ scope }">
          <el-link :underline="false" type="warning" @click="openEdit(scope.row)">编辑</el-link>
          <el-link :underline="false" type="info">删除</el-link>
        </template>
      </my-table>
    </div>

    <!--审核设置弹窗组件-->
    <projectConfiguration-edit :data="current" :visible.sync="showEdit"/>

  </div>
</template>

<script>
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getfacilitatorApi, set_statusApi,
} from '@/api/custom'

//引入项目配置弹窗组件
import ProjectConfigurationEdit from './components/projectConfiguration-edit.vue'


export default {
  components:{
    ProjectConfigurationEdit
  },

  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "订单类型",
          // type: "index",
          // width: "70"
          prop: "id",
          isShow: true,
          width: '150',
          fixed: 'left'
        },
        {
          label: "项目属性",
          prop: "id",
          isShow: true,
          width: '150'
        },
        {
          label: "默认拍照模板",
          prop: "id",
          isShow: true,
        },
        {
          label: "操作",
          width: "250",
          slot: "operationSlot",
          isShow: true,
          fixed: 'right'
        },
      ],

      //审核设置
      current:null,
      showEdit:false,

    }
  },

  mounted() {
    // this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      getfacilitatorApi(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },

    //点击编辑按钮
    openEdit(row){
      this.current = row;
      this.showEdit = true;
    },

    //点击返回按钮
    returnPage(){
      this.$emit('showxiangmu',false) ;
    },


  },


}
</script>

<style lang="scss" scoped>

</style>
